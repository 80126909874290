// @flow strict
import React from 'react';
import Author from './Author';
import Contacts from './Contacts';
import Copyright from './Copyright';
import Menu from './Menu';
import ViewCounter from '../Post/ViewCounter';
import styles from './Sidebar.module.scss';
import { useSiteMetadata } from '../../hooks';

type Props = {
  isIndex?: boolean,
};

const Sidebar = ({ isIndex }: Props) => {
  const {
    author, copyright, category, menu, tags
  } = useSiteMetadata();

  return (
    <div className={styles['sidebar']}>
      <div className={styles['sidebar__inner']}>
        <Author author={author} isIndex={isIndex} />
        <Menu menu={menu} />

        <div className="category-tags">
          <strong>Category</strong>
          <Menu menu={category} />
          {/* <strong>Tags</strong>
          <Menu menu={tags} /> */}
        </div>
        <Contacts contacts={author.contacts} />
        <Copyright copyright={copyright} />
        <strong><ViewCounter slug='home' /> views</strong>
      </div>
    </div>
  );
};

export default Sidebar;
