// ViewCounter.js
import React, { useEffect, useState } from 'react';
import firebase from 'gatsby-plugin-firebase';
import StarRatingComponent from 'react-star-rating-component';

import { updateRating, updateRusers } from '../../../lib/firebaseTxn';
import nFormatter from '../../../lib/nFormatter';
import styles from './StarRating.module.scss';

const StarRating = ({ slug }) => {
  const [rating, setRating] = useState(5);
  const [rusers, setRusers] = useState(1);
  const [totalRusers, setTotalRusers] = useState('0');
  const [starRate, setStarRate] = useState(5);
  const id = slug && slug.replace('/posts/', '');

  useEffect(() => {
    // 1 is displayed for a split second and then the correct count
    // This is a workaround
    const onRating = (rating) => {
      const count = rating.val() || 5;
      setRating(count);
    };

    const onRusers = (rusers) => {
      const count = rusers.val() || 1;
      setRusers(count);
      setTotalRusers(nFormatter(count));
    };

    firebase.database().ref('/rating').child(id).on('value', onRating);
    firebase.database().ref('/rusers').child(id).on('value', onRusers);

    return () => {
      if (firebase.database()) {
        firebase.database().ref('/rating').child(id).off('value', onRating);
        firebase.database().ref('/rusers').child(id).off('value', onRusers);
      }
    };
  }, [id]);

  useEffect(() => {
    setStarRate(Math.floor(rating / rusers));
  }, [rusers, rating]);

  const onStarClick = (nextValue) => {
    const newRating = rating + nextValue;
    setRating(newRating);
    updateRating(id, newRating);
    updateRusers(id);
  };

  return (
    <div className={styles['star__rating']}>
      <StarRatingComponent
        name="rate1"
        starCount={5}
        value={starRate}
        onStarClick={onStarClick}
      />
      <div className={styles['star__rating-rate']}>({totalRusers})</div>
    </div>
  );
};

export default StarRating;