// @flow strict
import React from 'react';
import { Link } from 'gatsby';
import type { Edges } from '../../types';
import styles from './Feed.module.scss';
import ViewCounter from '../Post/ViewCounter';
import StarRating from '../Post/StarRating';

type Props = {
  edges: Edges
};

const Feed = ({ edges }: Props) => (
  <div className={styles['feed']}>
    {edges.map((edge) => (
      <div className={styles['feed__item']} key={edge.node.fields.slug}>
        <div className={styles['feed__item-meta']}>
          <time className={styles['feed__item-meta-time']} dateTime={ new Date(edge.node.frontmatter.date).toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' })}>
          { new Date(edge.node.frontmatter.date).toLocaleDateString('en-US', { year: 'numeric', month: 'long' })}
          </time>
          <span className={styles['feed__item-meta-divider']} />
          <span className={styles['feed__item-meta-category']}>
            <Link to={edge.node.fields.categorySlug} className={styles['feed__item-meta-category-link']}>{edge.node.frontmatter.category}</Link>
          </span>
        </div>
        <h2 className={styles['feed__item-title']}>
          <Link className={styles['feed__item-title-link']} to={edge.node.fields.slug}>{edge.node.frontmatter.title}</Link>
        </h2>
        <p className={styles['feed__item-description']}>{edge.node.frontmatter.description}</p>
        <div className={styles['feed__item-footer']}>
          <Link className={styles['feed__item-readmore']} to={edge.node.fields.slug}>Read more...</Link>
          {/* <span className={styles['feed__item-footer__view__count']}>views: 1</span> */}
          <span className={styles['feed__item-footer__view__count']}>
            views: <ViewCounter slug={edge.node.fields.slug} type="read" />
            <StarRating slug={edge.node.fields.slug} />
          </span>
        </div>
      </div>
    ))}
  </div>
);

export default Feed;
